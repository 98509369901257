enum notes {
  DOCUMENTS_REQUEST = 'DOCUMENTS_REQUEST',
  POWER_OF_ATTORNEY_REQUEST = 'POWER_OF_ATTORNEY_REQUEST',
  INFO_EXCLUSION = 'INFO_EXCLUSION',
  INFO_LOWERED_MARGIN = 'INFO_LOWERED_MARGIN',
  INFO_KNOCK_DOWN_REFUSED = 'INFO_KNOCK_DOWN_REFUSED',
  INFO_ITEM_PAYMENT_REFUSED = 'INFO_ITEM_PAYMENT_REFUSED',
  INFO_MARGIN_AUTOMAT = 'INFO_MARGIN_AUTOMAT',
  INFO_PRINCIPAL_ORIGINAL = 'INFO_PRINCIPAL_ORIGINAL',
  INFO_DOCUMENT_ORIGINAL = 'INFO_DOCUMENT_ORIGINAL',
  INFO_MARGIN_BAILIFF = 'INFO_MARGIN_BAILIFF',
  INFO_REFUSE_KNOCKED = 'INFO_REFUSE_KNOCKED',
  INFO_COMPLAINT_BAILIFF_DECISION = 'INFO_COMPLAINT_BAILIFF_DECISION',
  INFO_COMPLAINT_COURT_DECISION = 'INFO_COMPLAINT_COURT_DECISION',
  INFO_WAIVER_PERIOD = 'INFO_WAIVER_PERIOD',
  INFO_WAIVER_REQUEST = 'INFO_WAIVER_REQUEST',
  INFO_WAIVER_DECISION = 'INFO_WAIVER_DECISION',
  INFO_KNOCK_DOWN_WINNER = 'INFO_KNOCK_DOWN_WINNER', // Zawiadomienie o przybiciu i wygranej
  INFO_KNOCK_DOWN_BIDDER = 'INFO_KNOCK_DOWN_BIDDER' // Zawiadomienie o przybiciu
}

export default notes;
